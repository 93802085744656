import React from 'react'

function CustomButton({ text, onClick, buttonType, marginLeft, marginRight, marginTop, marginBottom, width, height, type }) {
    return (
        <>
            <button
                className={`
${buttonType === "secondary" ? "text-[#252E4B] hover:text-[#145ECC] focus:text-[#4287ED]  border border-[#DADAE4] bg-[#FFF] hover:border-[#145ecc]  hover:shadow-jobPostButtonShadow   focus:border-[#4287ED]  focus:shadow-buttonFocusShadow  focus:outline-none" :
                        buttonType === "link" ? "text-[#252E4B] hover:text-[#145ECC] focus:text-[#4287ED] " :
                            "text-[#fff] border border-solid bg-[#1369E9] hover:border-[#145ecc] hover:bg-[#145ecc] hover:shadow-jobPostButtonShadow   focus:border-[#4287ED] focus:bg-[#4287ED] focus:shadow-buttonFocusShadow  focus:outline-none"}
ml-[${marginLeft ? marginLeft : ""}] mr-[${marginRight ? marginRight : ""}] mt-[${marginTop ? marginTop : ""}] mb-[${marginBottom ? marginBottom : ""}] w-[${width ? width : "auto"}]
h-[${height ? height : "auto"}]
 px-[18px]
py-[12px]
 rounded-lg   flex items-center justify-center  text-[18px] not-italic font-normal
    
    `}
                onClick={onClick}
                type={type ?? "button"}
            >
                {text}
            </button>
        </>
    )
}

export default CustomButton