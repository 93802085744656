import React from 'react';

function Privacy() {
  return (
    <div className="bg-[#F3F5F9] min-h-screen flex justify-center items-center">
      <div className="max-w-screen-xl p-4 sm:p-6 lg:p-8 py-16  ">
        <div className="font-sans">
          <h1 className="text-3xl font-bold text-center my-8 text-blue-800">Privacy Policy</h1>
          <div className="table-of-contents border-l-4 border-blue-400 pl-4">
            <h2 className="text-blue-800">Table of Contents:</h2>
            <ol className="list-disc ml-4">
              <li><a href="#intro">Introduction</a></li>
              <li><a href="#dpo">Data Protection Officer</a></li>
              <li><a href="#useInfo">How we collect and use (process) your personal information</a></li>
              <li><a href="#siteUse">Use of the SproutsAI Inc. website</a></li>
              <li><a href="#cookies">Cookies and tracking technologies</a></li>
              <li><a href="#sharing">Sharing information with third parties</a></li>
              <li><a href="#transfer">Transferring personal data to the U.S.</a></li>
              <li><a href="#dsr">Data Subject rights</a></li>
              <li><a href="#storage">Data storage and retention</a></li>
              <li><a href="#children">Children's data</a></li>
              <li><a href="#qa">Questions, concerns, or complaints</a></li>
            </ol>
          </div>

          <div id="intro" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
            <h2 className="text-blue-800">Introduction</h2>
            <p>
              We understand that you are aware of and care about your own personal
              privacy interests, and we take that seriously. This Privacy Notice
              describes SproutsAI Inc’s policies and practices regarding its
              collection and use of your personal data, and sets forth your privacy
              rights. We recognize that information privacy is an ongoing
              responsibility, and so we will from time to time update this Privacy
              Notice as we undertake new personal data practices or adopt new privacy
              policies.
            </p>
          </div>

          <div id="cookies" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
            <h2 className="text-blue-800">Cookies and tracking technologies</h2>
            <p>
              SproutsAI Inc. makes available a comprehensive cookie notice that
              describes the cookies and tracking technologies used on SproutsAI Inc.
              website and provides information on how users can accept or reject them.
              To view the notice, please see SproutsAI Inc.'s Cookie Policy.
            </p>
          </div>

          <div id="sharing" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
            <h2 className="text-blue-800">Sharing information with third parties</h2>
            <p>
              The personal information SproutsAI Inc. collects from you is stored in
              one or more databases hosted by third parties located in the United
              States. These third parties do not use or have access to your personal
              information for any purpose other than cloud storage and retrieval. On
              occasion, SproutsAI Inc. engages third parties to send information to
              you, including information about our products, services, and events.
            </p>
            <p>
              A list of our third-party sub-processors can be found here in our list
              of SproutsAI Inc. Subprocessors.
            </p>
            <p>
              We do not otherwise reveal your personal data to non-SproutsAI Inc.
              persons or businesses for their independent use unless: (1) you request
              or authorize it; (2) it's in connection with SproutsAI Inc.-hosted and
              SproutsAI Inc. co-sponsored conferences as described above; (3) the
              information is provided to comply with the law (for example, compelled
              by law enforcement to comply with a search warrant, subpoena, or court
              order), enforce an agreement we have with you, or to protect our rights,
              property, or safety, or the rights, property, or safety of our employees
              or others; (4) the information is provided to our agents, vendors, or
              service providers who perform functions on our behalf; (5) to address
              emergencies or acts of God; or (6) to address disputes, claims, or to
              persons demonstrating legal authority to act on your behalf. We may also
              gather aggregated data about our services and website visitors and
              disclose the results of such aggregated (but not personally
              identifiable) information to our partners, service providers,
              advertisers, and/or other third parties for marketing or promotional
              purposes.
            </p>
            <p>
              The SproutsAI Inc. website connects with third-party services such as
              Facebook, LinkedIn, Twitter, and others. If you choose to share
              information from the SproutsAI Inc. website through these services, you
              should review the privacy policy of that service. If you are a member of
              a third-party service, the aforementioned connections may allow that
              service to connect your visit to our site to your personal data.
            </p>
            <p className='mt-3'>We do not share Google user data with third parties except:</p>

            <ul>
              <li><strong>Legal Compliance:</strong> To comply with legal obligations, enforce our terms and conditions, or protect our rights.</li>
            </ul>
          </div>

          <div id="transfer" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
            <h2 className="text-blue-800">Transferring personal data to the U.S.</h2>
            <p>
              SproutsAI Inc. has its headquarters in the United States. Information we
              collect about you will be processed in the United States. By using
              SproutsAI Inc.'s services, you acknowledge that your personal
              information will be processed in the United States. The United States
              has not sought nor received a finding of "adequacy" from the European
              Union under Article 45 of the GDPR. Pursuant to Article 46 of the GDPR,
              SproutsAI Inc. is providing for appropriate safeguards by entering
              binding, standard data protection clauses, enforceable by data subjects
              in the EEA and the UK. These clauses have been enhanced based on the
              guidance of the European Data Protection Board and will be updated when
              the new draft model clauses are approved.
            </p>
            <p>
              Depending on the circumstance, SproutsAI Inc. also collects and
              transfers to the U.S. personal data with consent; to perform a contract
              with you; or to fulfill a compelling legitimate interest of SproutsAI
              Inc. in a manner that does not outweigh your rights and freedoms.
              SproutsAI Inc. endeavors to apply suitable safeguards to protect the
              privacy and security of your personal data and to use it only consistent
              with your relationship with SproutsAI Inc. and the practices described
              in this Privacy Statement. SproutsAI Inc. also enters into data
              processing agreements and model clauses with its vendors whenever
              feasible and appropriate. Since it was founded, SproutsAI Inc. has
              received zero government requests for information.
            </p>
            <p>
              For more information or if you have any questions, please contact us at
              contacts@sproutsai.com.
            </p>
          </div>

          <div id="dsr" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
            <h2 className="text-blue-800">Data Subject rights</h2>
            <p>
              The European Union's General Data Protection Regulation (GDPR) and other
              countries' privacy laws provide certain rights for data subjects. Data
              Subject rights under GDPR include the following:
            </p>
            <ul className="list-disc ml-4">
              <li>Right to be informed</li>
              <li>Right of access</li>
              <li>Right to rectification</li>
              <li>Right to erasure</li>
              <li>Right to restrict processing</li>
              <li>Right of data portability</li>
              <li>Right to object</li>
              <li>Rights related to automated decision making including profiling</li>
            </ul>
            <p>
              This Privacy Notice is intended to provide you with information about
              what personal data SproutsAI Inc. collects about you and how it is used.
            </p>
            <p>
              If you wish to confirm that SproutsAI Inc. is processing your personal
              data, or to have access to the personal data SproutsAI Inc. may have
              about you, please contact us.
            </p>
            <p>
              You may also request information about: the purpose of the processing;
              the categories of personal data concerned; who else outside SproutsAI
              Inc. might have received the data from SproutsAI Inc.; what the source
              of the information was (if you didn't provide it directly to SproutsAI
              Inc.); and how long it will be stored. You have a right to correct
              (rectify) the record of your personal data maintained by SproutsAI Inc.
              if it is inaccurate. You may request that SproutsAI Inc. erase that data
              or cease processing it, subject to certain exceptions. You may also
              request that SproutsAI Inc. cease using your data for direct marketing
              purposes. In many countries, you have a right to lodge a complaint with
              the appropriate data protection authority if you have concerns about how
              SproutsAI Inc. processes your personal data. When technically feasible,
              SproutsAI Inc. will—at your request—provide your personal data to you.
            </p>
            <p>
              Reasonable access to your personal data will be provided at no cost. If
              access cannot be provided within a reasonable time frame, SproutsAI Inc.
              will provide you with a date when the information will be provided. If
              for some reason access is denied, SproutsAI Inc. will provide an
              explanation as to why access has been denied.
            </p>
            <p>
              For questions or complaints concerning the processing of your personal
              data, you can email us at contacts@sproutsai.com. Alternatively, if you
              are located in the European Union, you can also have recourse to the
              European Data Protection Supervisor or with your nation's data
              protection authority.
            </p>
          </div>


          How We Collect and Use (Process) Your Personal Information
          We collect the following Google user data through our application:
          Primary Google Account Email Address.
          Personal Info, including publicly available information.
          OpenID to associate you with your personal info on Google.
          Google Calendar Information, including the ability to see, edit and share Google Calendar.
          Google Meet Information, including the ability to create, edit, and see information about your Google Meet conferences created by the app.

          We use this data to:
          Provide and personalize our services.
          Improve our products and user experience.
          Communicate with users about updates and services.
          Manage calendar events and meetings effectively.

          <div id="dsrT" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
            <h2 className="text-blue-800"> How We Collect and Use (Process) Your Personal Information</h2>
            <p>
              We collect the following Google user data through our application:

            </p>
            <ul className="list-disc ml-4">
              <li>Primary Google Account Email Address.</li>
              <li>Personal Info, including publicly available information.</li>
              <li>OpenID to associate you with your personal info on Google.</li>
              <li>Google Calendar Information, including the ability to see, edit, share, and permanently delete all the calendars you can access using Google Calendar.</li>
              <li>Google Meet Information, including the ability to create, edit, and see information about your Google Meet conferences created by the app.</li>
              <li>Gmail Information to automate email communication between recruiters and candidates which is part of a hiring process.</li>
            </ul>
            <p>
              We use this data to:
            </p>
            <ul className="list-disc ml-4">
              <li>Provide and personalize our services.</li>
              <li>Improve our products and user experience.</li>
              <li>Communicate with users about updates and services.</li>
              <li>Manage calendar events and meetings effectively.</li>

            </ul>

          </div>



          <div id="storage" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
            <h2 className="text-blue-800">Data Privacy</h2>
            <p>
              We prioritize user privacy and ensure that your data is only used for providing our scheduling service and email sequencing. We do not share data with third-party AI tools or build databases using it. Your data is secure and not shared with any third parties for AI processing or any other purposes beyond the intended scheduling service and email sequencing.
            </p>
            <p>
              Our app complies with the Google API Services User Data Policy, including the Limited Use requirements. Read more <a href="https://developers.google.com/terms/api-services-user-data-policy" className='font-bold' rel="noreferrer" target="_blank" >here.</a>
            </p>
          </div>





          <div id="storage" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
            <h2 className="text-blue-800">Data storage and retention</h2>
            <p>
              Your personal data is stored by the SproutsAI Inc. on its servers, and
              on the servers of the cloud-based database management services the
              SproutsAI Inc. engages, located in the United States. The SproutsAI Inc.
              retains service data for the duration of the customer's business
              relationship with the SproutsAI Inc. and for a period of time
              thereafter, to analyze the data for SproutsAI Inc.'s own operations, and
              for historical and archiving purposes associated with SproutsAI Inc.'s
              services. SproutsAI Inc. retains prospect data until such time as it no
              longer has business value and is purged from SproutsAI Inc. systems.
            </p>
            <p>
              SproutsAI Inc. retains personal data and usage data only for as long as
              is necessary for the purposes set out in this Privacy Policy. We will
              retain and use your personal data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain your
              data to comply with applicable laws), resolve disputes, and enforce our
              legal agreements and policies.
            </p>
            <p>
              By default, we retain audio and video data, and meeting metadata
              including transcriptions. You can request we delete this data, which
              results in audio and video data being deleted within 3 days.
            </p>

            <p>You may request the deletion of your data by contacting us at <a href="mailto:contacts@sproutsai.com">contacts@sproutsai.com</a>. Specific data retention periods are as follows:</p>

            <ul>
              <li><strong>Non-sensitive Data:</strong> Retained for the duration of your use of our services.</li>
              <li><strong>Sensitive Data (Google Calendar, Meet and Gmail Information):</strong> Retained for the period necessary to provide the services or as required by law.</li>
            </ul>
          </div>

          {/* <div id="children" className="mt-8 border-l-4 border-blue-400 pl-4 py- */}
        </div>
        <div id="children" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
          <h2 className="text-blue-800">Children's data</h2>
          <p>
            We do not knowingly attempt to solicit or receive information from children.
          </p>

        </div>
        <div id="qra" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
          <h2 className="text-blue-800">Prohibited Uses</h2>
          <ul className="list-disc ml-4">
            <li>We do not use Google user data for:</li>
            <li> Targeted advertising.</li>
            <li>Selling to data brokers.</li>
            <li>Providing information to resellers.</li>
            <li>Any other purposes that do not directly improve or provide our application’s functionality.</li>

          </ul>
        </div>


        <div id="qa" className="mt-8 border-l-4 border-blue-400 pl-4 py-4">
          <h2 className="text-blue-800">Questions, concerns or complaints</h2>
          <h5>SproutsAI Inc.</h5>
          <h5>Niraj Rai</h5>
          <h5>contacts@sproutsai.com</h5>
          <h5>+1-408-799-8605</h5>

        </div>


      </div>
    </div>
  )
}

export default Privacy